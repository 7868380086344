import React from 'react';
import { tw } from 'twind';
import * as Sentry from '@sentry/react';
import Head from 'components/Head';
import Heading from 'components/01_tw/typography/Heading';

export default class Error extends React.Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null;
        if (err) {
            Sentry.captureException(err);
        }
        return { statusCode };
    }

    render() {
        const { statusCode } = this.props;
        const notFound = Number(statusCode) === 404;

        return (
            <div className={tw`text-center`}>
                <Head />
                <img
                    src={notFound ? '/static/images/404.png' : '/static/images/500.png'}
                    alt=""
                    className={tw`mt-48 inline-block`}
                />
                <Heading as="h1" align="center" className={tw`mt-14`} gutterBottom>
                    {notFound ? 'Page not found' : 'Aw, something went wrong'}
                </Heading>
                <a href="/" className={tw`mt-10 text(lg blue-500) hover:(text-blue-600)`}>
                    Go to usepastel.com
                </a>
            </div>
        );
    }
}
